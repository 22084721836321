import "./App.css";
import background from "../src/hero-bg.png";
import logo from "../src/logo.png";

function App() {
  return (
    <div className="App">
      <img className="main-bg" src={background}></img>
      <div className="logo-container">
        <img className="logo" src={logo}></img>
        {/* <div className="title">COMING SOON</div> */}
      </div>
    </div>
  );
}

export default App;
